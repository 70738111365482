/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

//export { wrapRootElement } from "./src/hooks/useAuth"
import React from 'react'
import { CurrentUserProvider } from "./src/components/CurrentUser";

export const wrapRootElement = ({ element }) => (
    <CurrentUserProvider>{element}</CurrentUserProvider>
)