const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"
//console.log("API_URL", process.env.API_URL)
//console.log("GATSBY_API_URL", process.env.GATSBY_API_URL)

export const callApi = async (path, method, body) => {
  let response
  if (method === "GET") {
    response = await fetch(`${API_URL}${path}`, {
      method,
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
  } else {
    response = await fetch(`${API_URL}${path}`, {
      method,
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(body),
    })
  }

  //console.log("callApi:response", response)
  const data = await response.json()
  //console.log("data", data)
  if (!response.ok) {
    if (path === "/auth/local" || path === "/auth/local/register") {
      //message in different structure
      //console.log("different error structure")
      throw `Error: ${data.message[0].messages[0].message}`
    } else {
      throw `Error: ${data.message}`
    }
  }
  //data = await response.json();

  return data
}

export const callApiNoAuth = async (path, method, body) => {
  const response = await fetch(`${API_URL}${path}`, {
    method,
    headers: {
      "content-type": "application/json",
    },
    //credentials: "include",
    body: JSON.stringify(body),
  })
  const data = await response.json()
  //console.log("path", path)
  if (!response.ok) {
    if (
      path === "/auth/local" ||
      // path === "/auth/forgot-password" ||
      path === "/auth/reset-password"
    ) {
      //message in different structure
      // console.log("different error structure")

      throw `Error: ${data.message[0].messages[0].message}`
    } else if (path === "/auth/local/register") {
      //message in different structure
      // console.log("register has different error structure")
      throw `Error: ${data.message[0].messages[0].message}`
    } else {
      throw `Error: ${data.message}`
    }
  }
  return data
}

export const getAge = birthDate =>
  Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10)

//Turn yyyy-mm-dd into readable
export const readableDate = date => {
  //sort date
  const realDate = new Date(date)
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  return dateTimeFormat.format(realDate)
}
